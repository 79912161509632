// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import Modal from "react-modal";

// Modal.setAppElement("#root");

// ReactDOM.render(
//   <App />,
//   document.getElementById("root")
// );

// import { createRoot } from 'react-dom/client';
// const container = document.getElementById('app');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App tab="home" />);

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
