import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryLink } from "components/misc/Links";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms of Service" }) => {
  return (
    <StyledDiv className="App">
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p class="mb-6">
              By using this website, and by clicking “I accept”, you are deemed
              to have read and agreed to the following terms and conditions.
              Please read these terms of service agreement carefully.
            </p>
            <p class="mb-6">
              The below terminology applies to these Terms and Conditions,
              Privacy Statement, Refund Policy, and any or all agreements. “You”
              and ” Your”, refers to you the person accessing this website and
              accepting the Company's terms and conditions. “The Company”, “We”,
              “Us”, ”Ourselves”, refers to the company. If you do not agree to
              be bound to this Agreement, you cannot use SocioDiary.
            </p>
            <p class="mb-6">
              Please read our{" "}
              <PrimaryLink href="/privacy" target="_blank">
                Privacy Policy
              </PrimaryLink>{" "}
              attentively for information on how we collect, use, store, and
              disclose your personal information. SocioDiary Privacy Policy is
              incorporated into and made a part of these Terms by this
              reference.
            </p>
            <p class="mb-6">
              The company maintains the right, at its sole discretion, to modify
              this Agreement at any time. If the Company makes any
              modifications, it will notify Users via the email address supplied
              by each User, and any such changes will be posted here. It is your
              responsibility to examine any such changes. Your continued use of
              SocioDiary after the posting of an amended Agreement constitutes
              your acceptance of any such modified terms.
            </p>
            <h3 class="mb-4">Services Overview:</h3>
            <p class="mb-6">
              The Service offers a social media management tool that enables
              users to schedule posts on social platforms, as well as additional
              design and analytics tools to assist customers to improve their
              social media content.
            </p>
            <h3 class="mb-4">Eligibility:</h3>
            <p class="mb-6">
              To use the Service, you must be at least 18 years old. By agreeing
              to these Terms, you represent and warrant to us that:
              <br />
              You are at least 18 years old.
              <br />
              You have not previously been suspended or removed from the
              Service.
              <br />
              Your registration and use of the Service comply with all
              applicable laws and regulations.
            </p>
            <h3 class="mb-4">Accounts and Registration:</h3>
            <p class="mb-6">
              To use the majority of the Service's features, you must first
              create an account. When you create an account, you may be asked to
              provide us with personal information such as your name, email
              address, or other contact information. You also agree that the
              information you provide us with is correct and that you will
              maintain it correct and up to date at all times.
            </p>
            <p class="mb-6">
              When you register, you will be prompted to enter a password. You
              are solely responsible for keeping your account and password
              secure, and you take responsibility for any actions that occur
              under your account.
            </p>
            <h3 class="mb-4">Services:</h3>
            <p class="mb-6">
              Subject to the terms and conditions of this Agreement, the User
              shall have access to SocioDiary for the duration of the user's
              Paid Subscription, as long as the User has a Paid Subscription.
              The company may change, modify, or improve the functionality or
              appearance of SocioDiary from time to time, which may involve the
              removal of some functionality, content, or integrations.
            </p>
            <p>
              Once you share your content to a social media platform, its use
              will be governed by that platform's privacy policy and by using
              them you are agreeing to be bound by their terms of service.
            </p>
            <h3 class="mb-4">Licenses:</h3>
            <h4 class="mb-4">Permission to use:</h4>
            <p class="mb-6">
              Subject to the ongoing and complete compliance with these Terms,
              SocioDiary offers the User a non-transferable, non-assignable,
              limited, non-exclusive, revocable license to use the service
              solely as permitted in this Agreement at the level of service for
              which you have paid all applicable Fees.
            </p>
            <h4 class="mb-4">User Restrictions:</h4>
            <p class="mb-6">
              Except and only to the extent that such a limitation is prohibited
              by relevant law, the user may not and may not allow others to:
            </p>
            <ul class="list-checked list-checked-circle list-checked-primary mb-6">
              <li>
                Sell, rent, lease, license, or assign the use of your account to
                others.
              </li>
              <li>
                Reproduce, distribute, publicly display, or publicly perform the
                Service.
              </li>
              <li>
                Use SocioDiary to upload content that violates another person's
                or property's copyright, trademark, publicity rights, or other
                intellectual property interests.
              </li>
              <li>Make modifications to the Service.</li>
              <li>
                Interfere with or disrupt any aspect of the Service, including
                any security or access control mechanism.
              </li>
            </ul>
            <h3 class="mb-4">Proprietary Rights:</h3>
            <h4 class="mb-4">Company's Intellectual Property:</h4>
            <p class="mb-6">
              The whole service is owned and operated by SocioDiary. All the
              elements and materials of the service that are provided by
              SocioDiary are protected by intellectual property copyright,
              trademark, and other laws.
            </p>
            <h4 class="mb-4">User's Intellectual Property:</h4>

            <p class="mb-6">
              The user maintains all intellectual property rights, including
              copyrights, over any information, contributed to Social Media
              Platforms using this service including social media posts and
              other content which may be comprised of messages, reviews, photos,
              video, images, data, text, and other types of works (“User
              Content”).
            </p>
            <p class="mb-6">
              By submitting User Content to or through the Service, you grant
              SocioDiary a non-exclusive, royalty-free, worldwide,
              non-sublicensable, limited license to access, host, store,
              transfer, display, perform, reproduce, modify for purpose of
              formatting for display, and distribute your User Content, in whole
              or in part, in any media formats and through any media channels
              now known or hereafter developed.
            </p>
            <h3 class="mb-4">Payment terms:</h3>
            <ul class="list-checked list-checked-circle list-checked-primary mb-6">
              <li>
                When you register for the appropriate premium service, you will
                be required to pay fees for premium features of the Service.
              </li>
              <li>
                You will have the chance to review and accept the fees that you
                will be charged before you pay.
              </li>
              <li>
                Fees vary depending on the plan, with several pricing options
                available for individual users and organizations.
              </li>
              <li>
                The Company reserves the right to determine the price for the
                Service.
              </li>
            </ul>

            <h3 class="mb-4">Prohibited Conduct:</h3>

            <p class="mb-6">By using our service, you agree not to:</p>
            <ul class="list-checked list-checked-circle list-checked-primary mb-6">
              <li>
                Use it for any illegal purpose or in violation of any local,
                national, or international legislation.
              </li>
              <li>
                Violate, or encourage others to violate, any third-party right,
                including violating or misappropriating any intellectual
                property right of a third party.
              </li>
              <li>
                Undertake any fraudulent activity, such as impersonating another
                person or entity, claiming a false association, gaining
                unauthorized access to another Service account, or lying about
                your age or date of birth.
              </li>
              <li>
                Sell or transfer the access granted under these terms or any
                materials.
              </li>
              <li>
                Attempt to do any of the activities mentioned in this Section.
                Aid or permit any other person to commit any of the acts
                described above.
              </li>
            </ul>
            <h3 class="mb-4">
              Terms, Termination, and modification of the service:
            </h3>
            <p class="mb-6">
              The Company reserves the right to terminate your access to the
              Service, without notice, for any reason, including without
              limitation, if you breach these Terms.
            </p>
            <h4 class="mb-4">Terms:</h4>
            <p class="mb-6">
              All the terms mentioned in this document are effective the moment
              you accept the Terms or initially download, install, access, or
              use the Service until you terminate.
            </p>
            <h4 class="mb-4">Termination:</h4>
            <p class="mb-6">
              We reserve the right to terminate these Terms or your account on
              the Service, as well as suspend or terminate your access to the
              Service, once any of the mentioned terms are violated.
            </p>
            <p class="mb-6">
              When these Terms come to an end your licensing rights will be
              terminated, you must halt all usage of the service immediately,
              and you will no longer be authorized to access your account or the
              service. No refunds shall be offered, once a service is deemed to
              have begun and is, for all intents and purposes, underway. Any
              payments paid to us in consideration of the supply of unused
              Services will be refunded.
            </p>
            <h4 class="mb-4">Modification of the service:</h4>
            <p class="mb-6">
              SocioDiary maintains the right to modify or terminate the Service
              at any time, temporarily or permanently, without notice to you
              (including by restricting or discontinuing specific features of
              the Service). SocioDiary is not responsible for any changes to the
              Service, as well as any suspension or termination of your access
              to or use of the Service. Also, the fees for premium services are
              non-refundable.
            </p>
            <h3 class="mb-4">Disclaimers and Limitations of Liability:</h3>
            <p>
              THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE
              SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. TO
              THE EXTENT ALLOWED BY LAW, THE COMPANY DISCLAIMS ANY WARRANTIES OF
              ANY KIND REGARDING SOCIODIARY. AND BOTH COMPANY AND SOCIODIARY
              EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, INCLUDING, WITHOUT
              LIMITATION: (A) WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE,
              MERCHANTABILITY, OR NON-INFRINGEMENT ; (B) ANY WARRANTIES AS TO
              THE AVAILABILITY, ACCURACY, OR COMPLETENESS OF THE MATERIALS,
              SOFTWARE, CONTENT, SUPPORT, DOCUMENTATION, INFORMATION, PRODUCTS,
              OR SERVICES WHICH ARE PART OF SOCIODIARY, AND (C) ANY WARRANTY
              THAT SOCIODIARY WILL ALWAYS BE AVAILABLE, ERROR-FREE, ACCESSIBLE,
              TIMELY, OR SECURE.
            </p>
            <p class="mb-6">
              THE COMPANY DISCLAIMS ANY LIABILITY OR RESPONSIBILITY FOR USER
              CONTENT OR THE BEHAVIOR OF SOCIAL MEDIA PLATFORMS. IN NO EVENT
              WILL THE COMPANY, ITS SUBSIDIARIES, AFFILIATES, LICENSORS,
              EMPLOYEES, AGENTS, OR CONTRACTORS BE LIABLE TO THE USER FOR ANY
              DAMAGES OR LOSSES, INCLUDING WITHOUT LIMITATION INDIRECT,
              CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES RESULTING
              FROM OR CAUSED BY SOCIODIARY, THE MATERIALS, THE SOFTWARE, THE
              CONTENT, THE SUPPORT, THE DOCUMENTATION, OR ANY ERRORS OR
              OMISSIONS IN SOCIODIARY OR THE INTEGRATION WITH SOCIAL MEDIA
              PLATFORMS, EVEN IF COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES. ANY LIABILITY OF COMPANY, ITS SUBSIDIARIES, AFFILIATES,
              LICENSORS, EMPLOYEES, AGENTS, OR CONTRACTORS, INCLUDING WITHOUT
              LIMITATION ANY LIABILITY FOR DAMAGES CAUSED BY ANY FAILURE OF
              PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT,
              DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, HACKING,
              TAMPERING, UNAUTHORIZED USE, COMMUNICATIONS LINE FAILURE, THEFT,
              OR DESTRUCTION OR UNAUTHORIZED ACCESS TO OR ALTERATION OF
              SOCIODIARY OR SOCIAL MEDIA PLATFORMS, OR USE OF RECORDS, WHETHER
              FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, OR UNDER
              ANY OTHER CAUSE OF ACTION, CLAIMS OR DAMAGES, INCLUDING, WITHOUT
              LIMITATION, DAMAGES FOR LOSS OF BUSINESS, BUSINESS PROFITS,
              BUSINESS INTERRUPTION, BUSINESS INFORMATION, DATA LOSS OR
              CORRUPTION, OR ANY OTHER PECUNIARY LOSS ARISING OUT OF OR RELATING
              TO THE USE OF OR THE INABILITY TO USE SOCIODIARY OR SOCIAL MEDIA
              PLATFORMS, SHALL BE STRICTLY LIMITED TO THE AMOUNT PAID TO COMPANY
              BY OR ON BEHALF OF USER IN CONNECTION WITH SOCIODIARY IN THE THREE
              (3) MONTHS PRIOR TO THE CLAIMED INJURY OR DAMAGE. THE COMPANY DOES
              NOT REPRESENT OR ENDORSE THE ACCURACY, CURRENTNESS, OR RELIABILITY
              OF ANY ADVICE, OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED,
              UPLOADED, OR DISTRIBUTED THROUGH SOCIODIARY, INCLUDING BUT NOT
              LIMITED TO, THE MATERIALS, THE SOFTWARE, THE CONTENT, THE SUPPORT,
              AND/OR THE DOCUMENTATION. USER ACKNOWLEDGES THAT ANY RELIANCE UPON
              ANY SUCH OPINION, ADVICE, STATEMENT, OR INFORMATION SHALL BE AT
              THE USER'S SOLE RISK. SOME JURISDICTIONS DO NOT ALLOW FOR SOME OF
              THE LIMITATIONS OR EXCLUSIONS AS SET FORTH HEREIN. USERS MAY HAVE
              OTHER RIGHTS WHICH VARY FROM JURISDICTION TO JURISDICTION.
            </p>

            <h3 class="mb-4">General Terms:</h3>
            <p class="mb-6">
              These Terms, along with the{" "}
              <PrimaryLink href="/privacy" target="_blank">
                Privacy Policy
              </PrimaryLink>{" "}
              and any other agreements expressly incorporated by reference into
              these Terms, constitute your full and exclusive understanding and
              agreement with SocioDiary about your use of the Service. Without
              our prior written approval, you may not assign or transfer these
              Terms or your rights under them, in whole or in part, by operation
              of law or otherwise. Without notice or consent, we may assign
              these Terms at any time.
            </p>
            <p class="mb-6">
              The failure to require performance of any provision will not
              affect our right to require performance at any other time after
              that, nor will a waiver by us of any breach or default of these
              Terms, or any provision of these Terms, be a waiver of any
              subsequent breach or default or a waiver of the provision itself.
              The use of section headers in these Terms is purely for
              convenience and will have no impact on how any provision is
              interpreted. The word "including" is used throughout these Terms
              to signify "including but not limited to." If any part of these
              Terms is found to be invalid or unenforceable, the remaining
              sections will continue in full force and effect.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </StyledDiv>
  );
};
