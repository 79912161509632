import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import throttle from "lodash.throttle";

/* framer-motion and useInView here are used to animate the sections in when we reach them in the viewport
 */
import { motion } from "framer-motion";
import useInView from "use-in-view";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

function AnimationReveal({ disabled, children, inView, animeRef }) {
  if (disabled) {
    return <>{children}</>;
  }

  if (!Array.isArray(children)) children = [children];

  const directions = ["left", "right"];
  const childrenWithAnimation = children.map((child, i) => {
    return (
      <AnimatedSlideInComponent
        key={i}
        direction={directions[i % directions.length]}
        animeRef={animeRef}
        inView={inView}
      >
        {child}
      </AnimatedSlideInComponent>
    );
  });
  return <>{childrenWithAnimation}</>;
}

function AnimatedSlideInComponent({
  direction = "left",
  offset = 30,
  animeRef,
  inView,
  children,
}) {
  const x = { target: "0%" };

  if (direction === "left") x.initial = "-150%";
  else x.initial = "150%";

  return (
    <motion.section
      initial={{ x: x.initial }}
      animate={{
        x: inView && x.target,
        transitionEnd: {
          x: inView && 0,
        },
      }}
      transition={{ type: "spring", damping: 19 }}
      ref={animeRef}
    >
      {children}
    </motion.section>
  );
}

export default (props) => {
  const [offset, setOffset] = useState(30);
  //const [ref, inView] = useInView(30);

  const [ref, setRef] = useState(null);
  const [inView, setInView] = useState(false);
  useEffect(() => {
    const elementYPos = () => {
      return (
        ref.getBoundingClientRect().top +
        window.screenY -
        window.innerHeight +
        offset
      );
    };

    if (!ref) {
      return;
    }

    const handleScroll = throttle(() => {
      console.log("1");
      console.log(ref);
      if (!ref) {
        return;
      }

      if (window.scrollY >= elementYPos()) {
        window.removeEventListener("scroll", handleScroll);
        setInView(true);
      }
    }, 200);
    window.addEventListener("scroll", handleScroll, { passive: true });
    handleScroll();

    setOffset(30);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [ref, offset]);

  return (
    <StyledDiv className="App">
      <AnimationReveal animeRef={setRef} inView={inView} {...props} />
    </StyledDiv>
  );
};
